import cookieService from '@/services/cookies';
import { useToast } from "vue-toastification"

/**
 * A PCI DSS requirement is that inactive users for 15
 * minutes need to be re-authenticated (logged back in)
 * to the system to resume their work.
 * 
 * This class monitors users and ensures we stick to that.
 */
export default class ActivityService {

  constructor() {
    if (window.location.hostname.includes("localhost")) {
      console.log("ActivityService - Disabled on localhost");
      return;
    }

    const skipName = process.env.VUE_APP_DISABLE_ACTIVITY_SERVICE
    if (skipName && skipName !== "false") {
      console.log(`ACTIVITY SERVICE - DISABLED FOR THIS ENVIRONMENT: ${skipName}`);
      return
    }
    console.log("ACTIVITY SERVICE - ONLINE");

    this.idleTime = 0;

    this.idleInterval = setInterval(this.timerIncrement, 60000);

    window.addEventListener("click", this.resetIdleTime);
    window.addEventListener("keypress", this.resetIdleTime);
    window.addEventListener("mousemove", this.resetIdleTime);
    window.addEventListener("scroll", this.resetIdleTime);
    window.addEventListener("keydown", this.resetIdleTime);
    window.addEventListener("resize", this.resetIdleTime);
    window.addEventListener("load", this.resetIdleTime);
    window.addEventListener("touchmove", this.resetIdleTime);
    window.addEventListener("touchstart", this.resetIdleTime);
  }

  resetIdleTime() {
    this.idleTime = 0;
  }

  timerIncrement() {

    const toast = useToast();

    const toastDefault = { 
      position: "top-center",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    
    console.log(`${this.idleTime}`)
    console.log("Timerincrement - moving up idle time")
    
    this.idleTime = this.idleTime + 1;
    
    console.log(`${this.idleTime}`)
    console.log("Timerincrement - idletime incremented")

    /**
     * Run warnings every five minutes
     */
    if (this.idleTime === 5) { // 5 minutes
      toast.info("5 minutes of inactivity detected. You will be logged out after 15 minutes of inactivity.", toastDefault);
    }

    if (this.idleTime === 10) { // 10 minutes
      toast.info("10 minutes of inactivity detected. You will be logged out after 15 minutes of inactivity.", toastDefault);
      this.tenMinuteWarning = true;
    }

    if (this.idleTime === 14) { // 14 minutes - 1 minute before logoff
      toast.info("14 minutes of inactivity detected. You will be logged out after 15 minutes of inactivity.", toastDefault);
    }

    if (this.idleTime === 15) { // 15 minutes
      toast.info("15 minutes of inactivity gone by. Logging you out of the application.")
      cookieService.logout();
    }
  }
}
