<template>
  <div class="management-page card-2">
    <span style="color: #383838; font-size: 24px" class="title">Merchants</span>
    <AgGrid
      apiurl="/api/client/all"
      :columns="[]"
      :define-defs="columnDefs"
      :side-bar="true"
      :status-bar="statusBar"
      group-panel=""
      :move-front="['Legal Name']"
      :excel-options="{ fileName: 'Merchants.xlsx' }"
      height="92%"
      grid-config-key="merchant-accounts"
      @row2Selected="
        (row) => {
          // commenting out to just get this working
         // if (row[0].data[4] === 'LIVE') {
            router.push({
              name: 'Account',
              params: { clientid: row[0].data.id }
            });
         // } 
          // else {
          //   router.push({ name: 'Onboard', params: { id: row[0].data.id } });
          // }
        }
      "
    ></AgGrid>
  </div>
  <SubpricingReport />
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import useService from '@/composables/common/services';
import AgGrid from '@/components/AgGrid.vue';
import SubpricingReport from '@/components/reporting/SubpricingReport.vue';

const updatingData = ref(false);
const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agAggregationComponent',
      align: 'right'
    }
  ]
};
const route = useRoute();
const router = useRouter();
const { apiService } = useService();

const store = useStore();
const role = computed(() => {
  return store.getters['role'];
});

const columnDefs = [
  {
    headerName: 'Parent',
    colId: 'Parent',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      console.log('COLUMN PARAMS', params);
      if (!params.data) return '';
      return params?.data?.parent;
    }
  },
  {
    headerName: 'Legal Name',
    colId: 'Legal Name',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params?.data?.legal_name;
    }
  },
  {
    headerName: 'DBA Name',
    colId: 'DBA Name',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params?.data?.dba_name;
    }
  },
  {
    headerName: 'Status',
    colId: 'Status',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params?.data?.config?.enabled;
    }
  },
  {
    headerName: 'Card Processor',
    colId: 'Card Processor',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return 'NA';
      return params?.data?.config?.payment;
    }
  },
  {
    headerName: 'ACH Processor',
    colId: 'ACH Processor',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return 'NA';
      return params?.data?.config?.ach;
    }
  },
  {
    headerName: 'Latest Activity',
    colId: 'Latest Activity',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params?.data?.updated_at;
    }
  }
];
</script>

<style lang="scss" scoped>
.loader {
  z-index: 2;
}

::v-deep(.client-docs-modal) {
  .modal-dialog {
    max-width: 750px !important;
  }

  .modal-content {
    min-height: 500px;
  }
}

::v-deep(.clients-onboarding-modal) {
  .modal-dialog {
    max-width: 1050px !important;
  }

  .modal-content {
    min-height: 500px;
  }
}

::v-deep(.client-notes-modal) {
  .modal-dialog {
    max-width: 700px !important;
  }

  .modal-content {
    min-height: 500px;
  }
}

.page-mode-dropdown {
  display: inline-block;
  min-width: 200px;
}

::v-deep(.page-mode-dropdown) {
  .value {
    font-size: 24px;
    color: var(--c-dark);
    border: none !important;
    background: transparent !important;
  }

  .text {
    font-weight: 600;
    width: auto;
    margin-right: 10px;
  }
}
</style>

<style lang="scss">
.clients {
  .ag-row {
    cursor: pointer;
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }

  .status-value {
    &.Rejected {
      background: #ffe4e4 !important;
      color: #b3322c !important;
    }

    &.Approved,
    &.Boarded,
    &.Active {
      color: var(--c-success);
      background: #ddffef;
    }
  }
}
</style>
