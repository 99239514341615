<template>
  <div class="dashboard-content">
    <!-- Virtual Terminal -->
    <div v-if="virtualTerminalPermissions" class="virtual-terminal">
      <div class="row gap-2">
        <div class="col-12 col-md-3 metric-cards d-flex justify-content-center" style="padding: 0 !important;">
          <button
            style="margin: 20px;"
            :disabled="!defaultApiKey"
            type="button"
            class="btn btn-primary"
            @click="
              () => {
                router.push(`/virtual-terminal/${defaultApiKey}?routing=${defaultPrivateApiKey}`);
              }
            "
          >
            Virtual Terminal
          </button>
        </div>
        <div class="col-12 col-md-3 metric-cards d-flex justify-content-center" style="padding: 0 !important;">
          <button
            style="margin: 20px;"
            type="button"
            :disabled="!defaultApiKey || !store.getters.hasPermissionOnMerchant(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL_CONFIGURATION, store.state.user?.account?.default_client?.merchant?.id)"
            class="btn btn-outline-dark"
            @click="
              () => {
                router.push(`/configure-terminal/${defaultApiKey}?routing=${defaultPrivateApiKey}`);
              }
            "
          >
            Configure Virtual Terminal
          </button>
        </div>
        <div class="col-12 col-md-3 metric-cards d-flex justify-content-center" style="padding: 0 !important;">
          <button
            style="margin: 20px;"
              type="button"
              class="btn btn-outline-dark"
              @click="setIsTransactionToolsOpened"
          >
            Other Terminals
          </button>
        </div>
      </div>
    </div>
    <base-modal v-model="isTransactionToolsOpen" :is-large="true">
    <div class="transaction-tools">
      <div class="row">
        <!-- Virtual Terminal Links -->
        <div
          v-if="virtualTerminalPermissions"
          class="col-6 metric-cards"
        >
          <h5 class="card-title">Open a Virtual Terminal</h5>
          <ul class="vt-link-list p-0 list-group-flush">
            <li
              v-for="(merchant, index) in userMerchants"
              :key="index"
              class="list-group-item"
            >
              <a
                  class="text-primary"
                  style="text-decoration: none; cursor: pointer"
                  @click="
                    () => {
                      setIsTransactionToolsOpened();
                      router.push(`/virtual-terminal/${merchant.public_key}?routing=${merchant.apikey}`);
                    }
                  "
              >
                {{ merchant.name }} Virtual Terminal
              </a>
            </li>
          </ul>
        </div>

        <div
            v-if="virtualTerminalPermissions"
            class="col-6 metric-cards"
        >
          <h5 class="card-title">Configure a Virtual Terminal</h5>
          <ul class="vt-link-list p-0 list-group-flush">
            <li
                v-for="(merchant, index) in userMerchants"
                :key="index"
                class="list-group-item"
            >
              <a
                  v-if="store.getters.hasPermissionOnMerchant(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL_CONFIGURATION, merchant.id)"
                  class="text-primary"
                  style="text-decoration: none; cursor: pointer"
                  @click="
                    () => {
                      setIsTransactionToolsOpened();
                      router.push(`/configure-terminal/${merchant.public_key}?routing=${merchant.apikey}`);
                    }
                  "
              >
                {{ merchant.name }} Virtual Terminal
              </a>
              <span v-else class="text-secondary">{{ merchant.name }} (Not Configurable)</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
      <div name="footer">
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="setIsTransactionToolsOpened">
            Close
          </button>
        </div>
      </div>
    </base-modal>
      <!-- Metric Cards -->
    <div class="metric-cards">
      <h5>Transaction Metrics</h5>
      <div class="row">
        <div v-if="role === 'admin'" class="form-floating mb-2 ms-2">
          <select
            id="merchantDropdown"
            v-model="selectedMerchantId"
            class="form-select"
            style="width: 300px"
          >
            <option :value="null" disabled>Select an Account</option>
            <option
              v-for="merchant in merchants"
              :key="merchant.id"
              :value="merchant.id"
              :disabled="merchant.disabled"
            >
              {{ merchant.name }}
            </option>
          </select>
          <label for="merchantDropdown" class="ms-2">Merchant:</label>
        </div>
        <div class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 140px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Approved Transactions
            </p>
            <hr class="m-0 p-0" />
            <div v-if="statsLoading">
              <BaseSpinner />
            </div>
            <div v-else>
              <div class="metric-sub">Last 7 Days ({{ last7DateRange }})</div>
              <div class="m-0 metric-sub-data">{{ transLast7 }}</div>
              <div class="metric-sub">Last 30 Days ({{ last30DateRange }})</div>
              <div class="m-0 metric-sub-data">{{ transLast30 }}</div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 140px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Total Volume
            </p>
            <hr class="m-0 p-0" />
            <div v-if="statsLoading">
              <BaseSpinner />
            </div>
            <div v-else>
              <div class="metric-sub">Last 7 Days ({{ last7DateRange }})</div>
              <div class="m-0 metric-sub-data">${{ volLast7 }}</div>
              <div class="metric-sub">Last 30 Days ({{ last30DateRange }})</div>
              <div class="m-0 metric-sub-data">${{ volLast30 }}</div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xxl-8">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="min-height: 350px"
          >
            <div v-if="statsLoading">
              <p style="font-weight: bold; text-align: left; font-size: 14px">
                Monthly Volume Data
              </p>
              <BaseSpinner />
            </div>
            <MonthlyTotalsChart v-else :monthly-volume="monthlyVolumeData" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="role === 'admin' || role === 'parent'" class="metric-cards">
      <h5>Operations Metrics</h5>
      <div class="row">
        <div v-if="role === 'admin'" class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 100px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Open Risk Alerts
            </p>
            <hr class="m-0 p-0" />
            <div class="metric-sub-data">{{ totalRisk }}</div>
          </div>
        </div>
        <div v-if="role === 'admin'" class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 100px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Open Ops Alerts
            </p>
            <hr class="m-0 p-0" />
            <div class="metric-sub-data">{{ totalOps }}</div>
          </div>
        </div>
        <div v-if="role === 'admin'" class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 100px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Parents
            </p>
            <hr class="m-0 p-0" />
            <div class="metric-sub-data">{{ totalParents }}</div>
          </div>
        </div>
        <div
          v-if="role === 'admin' || role === 'parent'"
          class="col-12 col-md-6 col-xxl-3"
        >
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 100px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Merchants
            </p>
            <hr class="m-0 p-0" />
            <div class="metric-sub-data">{{ totalMerchants }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onBeforeMount, onMounted, ref, watch, watchEffect} from 'vue';
import http from '@/services/http';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { useStore } from 'vuex';
import { processMonthlyVolume } from '@/helpers';
import MonthlyTotalsChart from '@/components/reporting/MonthlyTotalsChart.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { router } from '@/router';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import BaseModal from "@/components/base/BaseModal.vue";
import { useToast } from 'vue-toastification';
import BaseButton from "@/components/base/BaseButton.vue";


// Get user data
const toast = useToast();
let toastdefault = { position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false}
const store = useStore();
const defaultClientName = computed(() => store.getters.defaultClientid);
const defaultClientId = computed(() => store.getters.defaultClientId);
/*
const user = computed(() => store.state.user);
*/
const userHasVirtualTerminalPermission = computed(() => {
  return store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL);
});
/*
const permissions = computed(() => store.state.permissions);
*/
const hasPermissions = computed(() => store.getters.merchantHasPermissions);

/**
 * Marks if the user has permissions to use the virtual terminal.
 * True if merchant has no permissions configured or
 * if the user has the virtual terminal permission.
 */
const virtualTerminalPermissions = computed(() => {
  if (userHasVirtualTerminalPermission.value) {
    return true;
  } else {
    return !hasPermissions.value;
  }
});

const role = computed(() => store.getters.role);
const isTransactionToolsOpen = ref(false);
/**
 * @typedef {{
 *   id: string
 *   name: string
 *   parent_id: string
 *   apikey: string
 *   public_key: string
 * }} UserMerchant
 */

/**
 * @type {import("vue").Ref<Array<UserMerchant>>}
 */
const userMerchants = ref([]);

const setIsTransactionToolsOpened = () => {
  if (isTransactionToolsOpen.value) {
    isTransactionToolsOpen.value = false;
  } else {
    isTransactionToolsOpen.value = true;
  }
}

const defaultApiKey = computed(() => {
  console.log(
    'Fetching default API key...',
    userMerchants.value,
    defaultClientId.value
  );
  const defaultMerchant = userMerchants.value.find(
    (merchant) => merchant.id === defaultClientId.value
  );
  if (defaultMerchant) {
    console.log(`Default API key found: ${defaultMerchant.public_key}`);
    return defaultMerchant.public_key;
  } else {
    console.log('No default API key found.');
    return '';
  }
});

const defaultPrivateApiKey = computed(() => {
  console.log(
      'Fetching default API key...',
      userMerchants.value,
      defaultClientId.value
  );
  const defaultMerchant = userMerchants.value.find(
      (merchant) => merchant.id === defaultClientId.value
  );
  if (defaultMerchant) {
    console.log(`Default API key found: ${defaultMerchant.apikey}`);
    return defaultMerchant.apikey;
  } else {
    console.log('No default API key found.');
    return '';
  }
});

// Fetch api keys attached to merchant for virtual terminals
const fetchUserMerchants = () => {
  http
    .get('/api/user/apikeys')
    .then((response) => {
      // test for empty list
      const merchantData = response.data.message;

      if (merchantData) {
        userMerchants.value = merchantData
          .map((merchant) => ({
            id: merchant.id,
            name: merchant.name,
            parent_id: merchant.parent_id,
            apikey: merchant.apikey,
            public_key: merchant.public_key
          }))
          .sort((a, b) =>
            a.name && b.name ? a.name.localeCompare(b.name) : 0
          );
      }
    })
    .catch((error) => {
      console.error('Error fetching user merchant data:', error);
    });
};

fetchUserMerchants();

const selectedMerchantId = ref(null);

watch(defaultClientId, () => {
  selectedMerchantId.value = selectedMerchantId.value || defaultClientId.value;
});

/**
 * @type {import("vue").Ref<string | null>}
 */
const selectedMerchantName = ref(null);

const totalParents = ref(null);
const totalMerchants = ref(null);

/**
 * @type {import("vue").ComputedRef<Array<MerchantDropDownOption>>}
 */
const merchants = computed(() => store.getters.merchantDropDownList);

const handleMerchantChange = () => {
  // Update selectedMerchantName when the user selects a merchant
  const selectedMerchant = merchants.value.find(
    (merchant) => merchant.id === selectedMerchantId.value
  );
  console.log('Selected merchant:', selectedMerchant);
  if (selectedMerchant) {
    selectedMerchantName.value = selectedMerchant.name;
    console.log('selectedMerchantName updated:', selectedMerchantName.value);
  }

  accountTotals();

  riskTotal();

  opsTotal();

  transactionTotals();
};

watchEffect(() => {
  if (selectedMerchantId.value) {
    handleMerchantChange();
  }
});

/*
const loadMerchants = async () => {
  if (!defaultClientId.value) {
    return;
  }
  try {
    const response = await http.get(
      `/api/clients?clientid=${defaultClientId.value}`
    );
    if (response.data.status) {
      console.log(response.data);
      console.log('response.data overhead just recieved in LOAD MERCHANTS');
      const unsortedMerchants = response.data.message.map((merchant) => ({
        id: merchant[0],
        name: merchant[1]
      }));

      // Sort the merchants array alphabetically by name
      merchants.value = unsortedMerchants
        .slice()
        .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
    }
  } catch (error) {
    console.error('Error loading merchants:', error);
  } finally {
    if (selectedMerchantId.value == null && defaultClientId.value) {
      selectedMerchantId.value = defaultClientId.value;
    }
  }
};

onBeforeMount(() => {
  loadMerchants();
});
*/

/*
watch(
  [() => selectedMerchantId.value, () => defaultClientId.value],
  ([newMerchantId, newClientId], [oldMerchantId, oldClientId]) => {
    // on first load, handles null merchant ID to update on incoming client ID
    // and refresh data

    if (newMerchantId != null && oldMerchantId == null && newClientId) {
      selectedMerchantId.value = newClientId;

      handleMerchantChange();
    }
  }
);
*/

const transLast7 = ref(null);
const transLast30 = ref(null);
const volLast7 = ref(null);
const volLast30 = ref(null);
const last7DateRange = ref('');
const last30DateRange = ref('');
const monthlyVolumeData = ref([]);
const statsLoading = ref(true);

const totalRisk = ref(null);
const totalOps = ref(null);

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1); // Subtract 1 to get yesterday's date

const eightDaysAgo = new Date(yesterday);
eightDaysAgo.setDate(yesterday.getDate() - 7);
const thirtyOneDaysAgo = new Date(yesterday);
thirtyOneDaysAgo.setDate(yesterday.getDate() - 30);

const dateRanges = () => {
  last7DateRange.value = `${eightDaysAgo.toLocaleDateString()} to ${yesterday.toLocaleDateString()}`;
  last30DateRange.value = `${thirtyOneDaysAgo.toLocaleDateString()} to ${yesterday.toLocaleDateString()}`;
};

dateRanges();

// Dashboard totals/cards
const transactionTotals = () => {
  statsLoading.value = true;
  http
    .get(`/api/stats?id=${selectedMerchantId.value}`)
    .then((response) => {
      // const { monthlyVolume } = response.data.message;
      const { last7, last30, monthlyVolume } = response.data.message;

      // Initialize variables to store the totals for last 7 and last 30 days
      let transTotalLast7 = 0;
      let volTotalLast7 = 0;
      let transTotalLast30 = 0;
      let volTotalLast30 = 0;
      // Calculate totals for last 7 days
      if (last7) {
        for (const entry of last7) {
          transTotalLast7 += parseFloat(entry.transactions) || 0;
          volTotalLast7 += parseFloat(entry.total) || 0;
        }
      }

      // Calculate totals for last 30 days
      if (last30) {
        for (const entry of last30) {
          transTotalLast30 += parseFloat(entry.transactions) || 0;
          volTotalLast30 += parseFloat(entry.total) || 0;
        }
      }

      if (monthlyVolume) {
        // monthlyChartData = processMonthlyVolume(monthlyVolume);
        monthlyVolumeData.value = monthlyVolume;
        console.log('monthlyChartData PROCESSED', monthlyVolume);
      }

      // Assign the calculated totals to the ref objects
      transLast7.value = transTotalLast7.toString();
      volLast7.value = volTotalLast7.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      transLast30.value = transTotalLast30.toString();
      volLast30.value = volTotalLast30.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
    .finally(() => {
      statsLoading.value = false;
    });
};

transactionTotals();

const accountTotals = () => {
  // Make the API call to fetch client metadata
  http
    .get(`/api/portal/clients-meta?id=${selectedMerchantId.value}`)
    .then((response) => {
      const data = response.data.message; // Assuming data is an array of objects

      // Initialize counters for Parent and Merchant
      let parentCount = 0;
      let merchantCount = 0;

      // Loop through the data and count based on the 'level' property
      data.forEach((client) => {
        if (client.level === 'Parent') {
          parentCount++;
        } else if (client.level === 'Merchant') {
          merchantCount++;
        }
      });

      // Assign counts to the ref variables
      totalParents.value = parentCount;
      totalMerchants.value = merchantCount;
    })
    .catch((error) => {
      console.error('Error fetching client metadata:', error);
    });
};

accountTotals();

const riskTotal = () => {
  http
    .get(`/api/risk?id=${selectedMerchantId.value}`)
    .then((response) => {
      if (Array.isArray(response.data.message)) {
        totalRisk.value = response.data.message.length;
      }
    })
    .catch((error) => {
      console.error('Error fetching risk data:', error);
    });
};

riskTotal();

const opsTotal = () => {
  http
    .get(`/api/operations?id=${selectedMerchantId.value}`)
    .then((response) => {
      if (Array.isArray(response.data)) {
        totalOps.value = response.data.message.length;
      }
    })
    .catch((error) => {
      console.error('Error fetching risk data:', error);
    });
};

opsTotal();
</script>

<style scoped>
.dashboard-content {
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.metric-cards {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
}

.metric-sub {
  font-weight: bold;
  color: gray;
  font-size: 12px;
}

.metric-sub-data {
  font-weight: lighter;
  color: gray;
  font-size: 20px;
}

.tools,
.vt-links,
.enrollment-form,
.transaction-tools {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.virtual-terminal {
  padding: 20px;
  border-radius: 8px;
}
.enrollment-form {
  background: #f8f9fa;
}

.buttons {
  display: flex;
  gap: 10px;
}

.btn {
  /* min-width: 200px; */
  margin-top: 10px;
}
</style>
