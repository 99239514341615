<template>
  <div class="terminals-page-css management-page">
    <!-- Existing Assign Terminal Location Modal -->
    <simple-modal v-model:state="terminalBeingLocationAssigned" :is-large="true" title="Assign Terminal Location">
      <h3>Assigning Location for Lane {{ terminalBeingLocationAssigned.lane_id }}</h3>
      <div ref="selectTerminalLocationContainer">
        <Select2
            v-if="selectTerminalLocationContainer"
            ref="selectTerminalLocationSelect2"
            :options="terminalAvailableLocationsSelect2"
            :settings="{ dropdownParent: selectTerminalLocationContainer, allowClear: true }"
            style="width: 400px;"
            :model-value="{
            id: terminalBeingLocationAssigned.sub_entity.id,
            label: terminalBeingLocationAssigned.sub_entity.name,
          }"
            @update:model-value="({id, label}) =>{
            terminalBeingLocationAssigned.sub_entity.name = label;
            terminalBeingLocationAssigned.sub_entity.id = id;
          }"
        />
      </div>

      <base-button
          :disabled="terminalLocationAssignedLoading"
          @click="terminalAssignLocation"
      >
        Save
      </base-button>
    </simple-modal>

    <!-- Add New Terminal Modal -->
    <simple-modal v-model:state="showAddTerminalModal" :is-large="true" title="Add New Terminal">
      <div class="add-terminal-form">
        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': newTerminalData.tpn }]"
              for="tpn"
          >
            TPN (required)
          </label>
          <input
              v-model="newTerminalData.tpn"
              type="text"
              id="tpn"
              class="form-control"
              placeholder="Enter Terminal TPN"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': newTerminalData.auth_key }]"
              for="authKey"
          >
            Auth Key (required)
          </label>
          <input
              v-model="newTerminalData.auth_key"
              type="text"
              id="authKey"
              class="form-control"
              placeholder="Enter Auth Key"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': newTerminalData.lane_id }]"
              for="laneId"
          >
            Lane ID (required)
          </label>
          <input
              v-model="newTerminalData.lane_id"
              type="text"
              id="laneId"
              class="form-control"
              placeholder="Enter Lane ID"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': newTerminalData.register_id }]"
              for="registerId"
          >
            Register ID (required)
          </label>
          <input
              v-model="newTerminalData.register_id"
              type="text"
              id="registerId"
              class="form-control"
              placeholder="Enter Register ID"
              required
          />
        </div>

        <!-- Optionally add more fields here if needed -->
        <!--
          For example:
          <div class="form-group">
            <label for="terminalType">Terminal Type</label>
            <select v-model="newTerminalData.terminal_type" id="terminalType" class="form-control">
              <option value="">Select Type</option>
              <option value="Dejavoo">Dejavoo</option>
            </select>
          </div>

          <div class="form-group">
            <label for="isActive">Is Active</label>
            <input type="checkbox" v-model="newTerminalData.is_active" id="isActive" />
          </div>
        -->

        <base-button :disabled="addingNewTerminal" @click="handleAddTerminal">
          Add Terminal
        </base-button>
      </div>
    </simple-modal>

    <!-- Order A Pax Device Modal -->
    <simple-modal v-model:state="showOrderPaxModal" :is-large="true" title="Order A Pax Device">
      <div class="add-terminal-form">
        <!-- ShipTo dropdown using merchant names -->
        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.shipTo }]"
              for="shipTo"
          >
            Ship To
          </label>
          <select
              v-model="orderPaxData.shipTo"
              id="shipTo"
              class="form-control"
              required
          >
            <option disabled value="">-- Select a Merchant --</option>
            <option
                v-for="(m,) in merchantNames"
                :key="m.id"
                :value="m.id"
            >
              {{ m.label }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.shipToContact }]"
              for="shipToContact"
          >
            Ship To Contact
          </label>
          <input
              v-model="orderPaxData.shipToContact"
              type="text"
              id="shipToContact"
              class="form-control"
              placeholder="Contact Person"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.shipToAddress }]"
              for="shipToAddress"
          >
            Ship To Address
          </label>
          <input
              v-model="orderPaxData.shipToAddress"
              type="text"
              id="shipToAddress"
              class="form-control"
              placeholder="Address"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.shipToCity }]"
              for="shipToCity"
          >
            Ship To City
          </label>
          <input
              v-model="orderPaxData.shipToCity"
              type="text"
              id="shipToCity"
              class="form-control"
              placeholder="City"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.shipToState }]"
              for="shipToState"
          >
            Ship To State
          </label>
          <input
              v-model="orderPaxData.shipToState"
              type="text"
              id="shipToState"
              class="form-control"
              placeholder="State"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.shipToZip }]"
              for="shipToZip"
          >
            Ship To Zip
          </label>
          <input
              v-model="orderPaxData.shipToZip"
              type="text"
              id="shipToZip"
              class="form-control"
              placeholder="Zip Code"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.shipToPhone }]"
              for="shipToPhone"
          >
            Ship To Phone
          </label>
          <input
              v-model="orderPaxData.shipToPhone"
              type="text"
              id="shipToPhone"
              class="form-control"
              placeholder="Phone Number"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.cardholderName }]"
              for="cardholderName"
          >
            Cardholder Name
          </label>
          <input
              v-model="orderPaxData.cardholderName"
              type="text"
              id="cardholderName"
              class="form-control"
              placeholder="Cardholder Name"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.CcNum }]"
              for="CcNum"
          >
            Credit Card Number
          </label>
          <input
              v-model="orderPaxData.CcNum"
              type="text"
              id="CcNum"
              class="form-control"
              placeholder="####-####-####-####"
              required
          />
        </div>

        <!-- Replacing the single ExpDate with two separate fields: Month and Year -->
        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.ExpMonth && orderPaxData.ExpMonth.length === 2 }]"
              for="ExpMonth"
          >
            Exp Month
          </label>
          <input
              v-model="orderPaxData.ExpMonth"
              type="text"
              id="ExpMonth"
              class="form-control"
              placeholder="MM"
              maxlength="2"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.ExpYear && orderPaxData.ExpYear.length === 2 }]"
              for="ExpYear"
          >
            Exp Year
          </label>
          <input
              v-model="orderPaxData.ExpYear"
              type="text"
              id="ExpYear"
              class="form-control"
              placeholder="YY"
              maxlength="2"
              required
          />
        </div>
        <!-- End of separate month/year fields -->

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.CVV2 }]"
              for="CVV2"
          >
            CVV2
          </label>
          <input
              v-model="orderPaxData.CVV2"
              type="text"
              id="CVV2"
              class="form-control"
              placeholder="###"
              required
          />
        </div>

        <div class="form-group">
          <label
              :class="['required-label', { 'valid-field': orderPaxData.billingZip }]"
              for="billingZip"
          >
            Billing Zip
          </label>
          <input
              v-model="orderPaxData.billingZip"
              type="text"
              id="billingZip"
              class="form-control"
              placeholder="Billing Zip Code"
              required
          />
        </div>

        <!-- Status Message (Success or Failure) -->
        <div v-if="orderPaxStatusMessage" style="margin-bottom: 1rem; color: #333;">
          {{ orderPaxStatusMessage }}
        </div>

        <base-button @click="handleOrderPax">
          Submit Order
        </base-button>
      </div>
    </simple-modal>

    <div class="title" style="color: #383838; font-size: 24px">
      Terminal Settings
    </div>

    <!-- "Add New Terminal" and "Order A Pax Device" buttons -->
    <div class="row">
      <div class="col-6 mt-3 mb-2">
        <base-button @click="showAddTerminalModal = {}">Add New Terminal</base-button>
      </div>
      <div class="col-6 mt-3 mb-2">
        <base-button class="ml-3" @click="showOrderPaxModal = {}">
          Order A Pax Device
        </base-button>
      </div>
    </div>

    <base-spinner v-if="loadingList" class="loader" />
    <AgGrid
        ref="agRef"
        :apiurl="apiUrl"
        :columns="[]"
        :define-defs="columnDefs"
        :side-bar="true"
        :status-bar="{}"
        :agg-func="undefined"
        :excel-styles="excelStyles"
        group-panel=""
        :counter="false"
        total-column-name=""
        :excel-options="{ fileName: 'Terminal Report.xlsx' }"
        height="84vh"
        :get-api-data-on-ready-enabled="false"
    ></AgGrid>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import AgGrid from '@/components/AgGrid.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { useStore } from 'vuex';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import BaseButton from '@/components/base/BaseButton.vue';
import http from '@/services/http';
import { computedAsync } from '@vueuse/core';
import SimpleModal from '@/components/base/SimpleModal.vue';

const agRef = ref(null);
const selectTerminalLocationContainer = ref(null);
const selectTerminalLocationSelect2 = ref(null);
const store = useStore();
const router = useRouter();
const toast = useToast();
const user = computed(() => !!store.state.user);
const hasPermissions = computed(() => store.getters.merchantHasPermissions);
const userHasTerminalAdmin = computed(() => store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.TERMINAL_ADMIN));

// Check user permissions
watch([user, userHasTerminalAdmin], () => {
  if (user.value && (hasPermissions.value && !userHasTerminalAdmin.value)) {
    toast.error('User does not terminal permission');
    router.push('/');
  }
});

const apiUrl = ref('/api/v1/terminals');
const loadingList = ref(false);

const excelStyles = [
  {
    id: 'dateType',
    dataType: 'dateTime',
    numberFormat: { format: 'yyyy-mm-dd hh:mm:ss' },
  },
];

/**
 * Terminal Model Fields
 * The columnDefs rely on these fields.
 */
const TERMINAL_V1_MODEL_FIELDS = [
  { field: 'sn', column: 'Serial Number' },
  { field: 'tpn', column: 'Terminal TPN' },
  { field: 'lane_id', column: 'Lane ID' },
  { field: 'register_id', column: 'Register ID' },
  { field: 'is_active', column: 'Is Active' },
  { field: 'terminal_type', column: 'Type' },
];

function initTerminalModelForEditing(value) {
  value.client = value.client || {};
  value.client.merchant = value.client.merchant || {};
  value.sub_entity = value.sub_entity || {};
  value.sub_entity.merchant = value.sub_entity.merchant || {};
  return value;
}

const columnDefs = computed(() => [
  {
    headerName: `Terminal Details`,
    children: [
      {
        headerName: 'ID',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data?.id;
        },
      },
      ...TERMINAL_V1_MODEL_FIELDS.map(({ field, column }) => ({
        headerName: column,
        filter: 'agTextColumnFilter',
        valueGetter: p => (p.data?.[field] || ''),
      })),
      {
        headerName: 'Location',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => `${p.data?.sub_entity?.name} (${p.data?.sub_entity?.merchant?.name})`,
      },
    ],
  },
  {
    headerName: 'Actions',
    children: [
      {
        headerName: 'Assign Location',
        filter: false,
        field: 'view',
        cellRenderer: () => `<p style="font-size: 12px; text-decoration: underline;">Open</p>`,
        onCellClicked: function(params) {
          let value = params.node.data;
          terminalBeingLocationAssigned.value = initTerminalModelForEditing(value);
        },
      },
    ],
  },
]);

const terminalBeingLocationAssigned = ref(null);

watch(
    [() => terminalBeingLocationAssigned.value, () => selectTerminalLocationSelect2.value],
    () => {
      if (!selectTerminalLocationSelect2.value) return;
      let ref = selectTerminalLocationSelect2.value;
      setTimeout(() => {
        let select = ref?.['select2'];
        select?.trigger('change');
        select?.select2({
          width: '100%',
          dropdownParent: selectTerminalLocationContainer.value,
        });
      }, 100);
    }
);

const terminalAvailableLocations = computedAsync(async () => {
  let response = await http.get(`/api/rbac/${store.state.user.account.default_client.id}/sub-entities`);
  return response.data.message;
}, []);
const terminalLocationAssignedLoading = ref(false);

const terminalAvailableLocationsSelect2 = computed(() => {
  return terminalAvailableLocations.value.map(e => ({
    id: e.id,
    label: `${e.name} (${e.merchant.name})`
  }));
});

async function terminalAssignLocation() {
  terminalLocationAssignedLoading.value = true;
  try {
    let response = await http.patch(
        `/api/v1/terminals/${terminalBeingLocationAssigned.value.id}`,
        {
          sub_entity: {
            id: terminalBeingLocationAssigned.value.sub_entity.id,
          },
        },
        {
          headers: { 'content-type': 'application/json' },
        }
    );
    Object.assign(terminalBeingLocationAssigned.value, response.data);
    loadingList.value = true;
    await new Promise(r => agRef.value?.refreshAPI(apiUrl.value, r));
  } finally {
    loadingList.value = false;
    terminalLocationAssignedLoading.value = false;
  }
}

// State for adding a new terminal
const showAddTerminalModal = ref(null);
const addingNewTerminal = ref(false);
const newTerminalData = ref({
  tpn: '',
  register_id: '',
  auth_key: '',
  lane_id: '',
  terminal_type: '', // optional
  is_active: true,   // optional
});

// Handle the Add Terminal form submission
async function handleAddTerminal() {
  // Validate required fields
  if (
      !newTerminalData.value.tpn ||
      !newTerminalData.value.register_id ||
      !newTerminalData.value.auth_key ||
      !newTerminalData.value.lane_id
  ) {
    toast.error('Please fill out all required fields.');
    return;
  }

  addingNewTerminal.value = true;
  try {
    // 1. POST to /api/terminal/link
    const linkPayload = {
      tpn: newTerminalData.value.tpn,
      registerId: newTerminalData.value.register_id,
      authKey: newTerminalData.value.auth_key,
      laneId: newTerminalData.value.lane_id,
    };
    let linkResponse = await http.post('/api/terminal/link', linkPayload, {
      headers: { 'content-type': 'application/json' },
    });

    // If link fails, show toast
    if (!linkResponse.data.success) {
      toast.error('Reload Page. Terminal May Have Been Added But Not Yet Linked. Contact Support if This Continues.');
      return;
    }

    // Assume linkResponse returns something like { data: { activationToken, laneId } }
    const { activationToken } = linkResponse.data.data;
    const laneId = newTerminalData.value.lane_id;

    // 2. POST to /api/terminal/activate with returned activationToken and laneId
    const activatePayload = {
      activationToken,
      laneId,
    };
    let activateResponse = await http.post('/api/terminal/activate', activatePayload, {
      headers: { 'content-type': 'application/json' },
    });

    if (!activateResponse.data.success) {
      toast.error('Terminal Added But Failed to Activate');
    } else {
      toast.success('Terminal Added and Activated Successfully');
    }

    // Refresh grid
    loadingList.value = true;
    await new Promise(r => agRef.value?.refreshAPI(apiUrl.value, r));
    showAddTerminalModal.value = null;
  } catch (error) {
    toast.error('Failed to Add Terminal');
  } finally {
    addingNewTerminal.value = false;
  }
}

/**
 * Now let's add the "Order A Pax Device" feature
 */

// State for showing the "Order A Pax Device" modal
const showOrderPaxModal = ref(null);

// Data for the Pax Device order
const orderPaxData = ref({
  shipTo: '',
  shipToContact: '',
  shipToAddress: '',
  shipToCity: '',
  shipToState: '',
  shipToZip: '',
  shipToPhone: '',
  cardholderName: '',
  CcNum: '',
  ExpMonth: '',
  ExpYear: '',
  CVV2: '',
  billingZip: '',
});

// Status message to display on the modal after form submission
const orderPaxStatusMessage = ref(null);

// Fetch merchant names for the "shipTo" dropdown
const merchantNames = computedAsync(async () => {
  try {
    const response = await http.get('/api/merchant-names');
    // response.data.merchant_names is an array like [ { "1": "Merchant1" }, { "2": "Merchant2" } ]
    if (response.data && response.data.message.merchant_names) {
      return response.data.message.merchant_names.map((mObj) => {
        const id = Object.keys(mObj)[0];
        const label = mObj[id];
        return { id, label };
      });
    }
    return [];
  } catch (error) {
    return [];
  }
}, []);

// Handle the Pax Device order form submission
async function handleOrderPax() {
  orderPaxStatusMessage.value = null; // Clear out any old messages

  // Validate that every field has a non-empty value,
  // plus we require that ExpMonth and ExpYear each be exactly 2 digits
  if (
      !orderPaxData.value.shipTo ||
      !orderPaxData.value.shipToContact ||
      !orderPaxData.value.shipToAddress ||
      !orderPaxData.value.shipToCity ||
      !orderPaxData.value.shipToState ||
      !orderPaxData.value.shipToZip ||
      !orderPaxData.value.shipToPhone ||
      !orderPaxData.value.cardholderName ||
      !orderPaxData.value.CcNum ||
      !orderPaxData.value.ExpMonth ||
      orderPaxData.value.ExpMonth.length !== 2 ||
      !orderPaxData.value.ExpYear ||
      orderPaxData.value.ExpYear.length !== 2 ||
      !orderPaxData.value.CVV2 ||
      !orderPaxData.value.billingZip
  ) {
    orderPaxStatusMessage.value = "Please fill out all required fields (ensure Exp Month/Year are two digits).";
    return;
  }

  // Concatenate ExpMonth and ExpYear to form 'ExpDate'
  const finalExpDate = orderPaxData.value.ExpMonth + orderPaxData.value.ExpYear;

  // Build final data to send
  const dataToPost = {
    ...orderPaxData.value,
    ExpDate: finalExpDate, // e.g. "1228"
  };

  try {
    // Example POST call to your backend with the required fields
    const response = await http.post('/api/order-pax', dataToPost, {
      headers: { 'content-type': 'application/json' },
    });

    if (response.data && response.data.status) {
      orderPaxStatusMessage.value = "Successful Response! You will receive your terminal in a few days.";
    } else {
      orderPaxStatusMessage.value = "Request failed. Please double check your data for valid inputs.";
    }
  } catch (error) {
    orderPaxStatusMessage.value = "Request failed. Please double check your data for valid inputs.";
  }
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}

.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

.add-terminal-form {
  .form-group {
    margin-bottom: 1rem;
    label {
      display: block;
      margin-bottom: 0.5rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
    }
  }
}

/* REQUIRED FIELD ASTERISK IN RED; TURNS GREEN WHEN .valid-field IS TRUE */
.required-label::after {
  content: '*';
  color: red;
  margin-left: 4px;
}
.valid-field::after {
  color: green !important;
}
</style>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.min.css';

.terminals-page-css {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>